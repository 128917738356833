import React from 'react';
import imga from "../assets/images/about/a.jpg";
import imgb from "../assets/images/about/b.jpg";
import imgc from "../assets/images/about/c.jpg";
import imgd from "../assets/images/about/d.jpg";

const AboutDescription = () => {
  return (
    <>
      <section className="py-40">
        <div className="flex flex-col px-8 mx-auto space-y-12 max-w-7xl xl:px-12">
          <div className="relative">
            <h2 className="w-full text-3xl font-bold text-center sm:text-4xl">About Total Installations Cape Town</h2>
            <p className="w-full py-8 mx-auto -mt-2 text-base text-center text-slate-400 font-medium sm:max-w-3xl">
              Welcome to Total Installations Cape Town, your premier destination for comprehensive installation and support services in Cape Town. We specialize in DStv installations, Openview setups, TV mounting, and CCTV system installations. Our mission is to provide you with unmatched service quality and innovative solutions that enhance your entertainment and security systems. Whether you're upgrading your home entertainment or improving your security infrastructure, our dedicated team ensures that every service is executed with precision and excellence.
            </p>
            <p className="w-full py-8 mx-auto text-base text-center text-slate-400 font-medium sm:max-w-3xl">
              At Total Installations Cape Town, we take pride in our attention to detail and commitment to customer satisfaction. Our expert technicians are well-versed in the latest technologies and trends in the industry, ensuring that you receive cutting-edge solutions tailored to your specific needs. With years of experience and a passion for excellence, we are your trusted partner for all your entertainment and security needs.
            </p>
          </div>
          <div className="flex flex-col mb-10 animated fadeIn sm:flex-row">
            <div className="flex items-center mb-16 sm:w-1/2 md:w-5/12 sm:order-last">
              <img className="rounded-lg shadow-xl" src={imga} alt="Comprehensive Installation Services" />
            </div>
            <div className="flex flex-col justify-center mt-5 mb-8 md:mt-0 sm:w-1/2 md:w-7/12 sm:pr-16">
              <h3 className="mt-2 text-xl font-semibold sm:text-left sm:text-2xl">Our Expert Services</h3>
              <p className="mt-5 text-base text-slate-400 sm:text-left lg:text-lg">
                At Total Installations Cape Town, we offer a full range of installation and maintenance services. Our offerings include:
                <ul className="list-disc list-inside mt-2 text-slate-400">
                  <li>DStv Installation: High-quality setup for all DStv packages to ensure crystal-clear reception and optimal performance.</li>
                  <li>Openview Setup: Professional installation of Openview decoders for a seamless viewing experience with a variety of channels.</li>
                  <li>TV Mounting: Expert mounting solutions for all types of TVs, including flat screens and smart TVs, with precise alignment and cable management.</li>
                  <li>CCTV Installation: Comprehensive security solutions with advanced CCTV systems to enhance your property’s safety and surveillance capabilities.</li>
                </ul>
                Our skilled team ensures that each service is delivered with the highest standards of quality and professionalism. Whether you need a new system installed or require maintenance and upgrades, we are here to provide prompt and efficient solutions.
              </p>
            </div>
          </div>
          <div className="flex flex-col mb-10 animated fadeIn sm:flex-row">
            <div className="flex items-center mb-16 sm:w-1/2 md:w-5/12">
              <img className="rounded-lg shadow-xl" src={imgb} alt="Our Commitment to Quality" />
            </div>
            <div className="flex flex-col justify-center mt-5 mb-8 md:mt-0 sm:w-1/2 md:w-7/12 sm:pl-16">
              <h3 className="mt-2 text-xl font-semibold sm:text-left sm:text-2xl">Our Commitment to Excellence</h3>
              <p className="mt-5 text-base text-slate-400 sm:text-left lg:text-lg">
                At Total Installations Cape Town, our commitment to excellence is reflected in every project we undertake. We are dedicated to providing high-quality services with a focus on customer satisfaction. Our commitment includes:
                <ul className="list-disc list-inside mt-2 text-slate-400">
                  <li>High Standards: We adhere to the highest industry standards to ensure that every installation is performed with precision and care.</li>
                  <li>Personalized Service: Each project is approached with a personalized touch, ensuring that our solutions meet your unique requirements.</li>
                  <li>Timely Completion: We value your time and strive to complete all projects efficiently and within the agreed-upon timeframe.</li>
                  <li>Ongoing Support: Our relationship with clients extends beyond the installation phase. We provide ongoing support and maintenance to address any issues or concerns that may arise.</li>
                </ul>
                We believe that our dedication to quality sets us apart and makes us the preferred choice for your entertainment and security needs.
              </p>
            </div>
          </div>
          <div className="flex flex-col mb-10 animated fadeIn sm:flex-row">
            <div className="flex items-center mb-16 sm:w-1/2 md:w-5/12 sm:order-last">
              <img className="rounded-lg shadow-xl" src={imgc} alt="Why Choose Total Installations Cape Town" />
            </div>
            <div className="flex flex-col justify-center mt-5 mb-8 md:mt-0 sm:w-1/2 md:w-7/12 sm:pr-16">
              <h3 className="mt-2 text-xl font-semibold sm:text-left sm:text-2xl">Why Choose Us?</h3>
              <p className="mt-5 text-base text-slate-400 sm:text-left lg:text-lg">
                Choosing Total Installations Cape Town comes with numerous benefits. Here’s why we are the best choice for your needs:
                <ul className="list-disc list-inside mt-2 text-slate-400">
                  <li>Experienced Professionals: Our team comprises seasoned professionals with extensive experience in the industry, ensuring expert service and installation.</li>
                  <li>Competitive Pricing: We offer competitive pricing without compromising on quality, providing you with the best value for your investment.</li>
                  <li>Flexible Scheduling: We understand that your time is valuable. We offer flexible scheduling options to accommodate your busy lifestyle.</li>
                  <li>Customer-Centric Approach: Our focus is on delivering exceptional customer service, from the initial consultation to the completion of the project and beyond.</li>
                  <li>Cutting-Edge Technology: We use the latest technology and equipment to ensure that your systems are installed and maintained to the highest standards.</li>
                </ul>
                By choosing us, you can be confident that you are receiving top-notch service and support tailored to your specific needs.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="py-16">
        <div className="flex flex-col px-8 mx-auto space-y-12 max-w-7xl xl:px-12">
          <div className="relative">
            <h2 className="w-full text-3xl font-bold text-center sm:text-4xl">Discover the Benefits of Choosing Total Installations Cape Town</h2>
            <p className="w-full py-8 mx-auto -mt-2 text-base text-center text-slate-400 font-medium sm:max-w-3xl">
              Learn about the numerous benefits of choosing Total Installations Cape Town for your home or business. Our commitment to providing high-quality, affordable, and tailored solutions ensures that you receive the best possible service and value.
            </p>
          </div>
          <div className="mb-10 animated fadeIn">
            <div className="mt-5">
              <h3 className="mt-8 text-xl font-semibold sm:text-left sm:text-2xl">Affordable, High-Quality Solutions</h3>
              <p className="mt-5 mb-10 pb-10 border-b-2 text-base text-slate-400 sm:text-left lg:text-lg lg:text-justify">
                We believe in offering high-quality entertainment and security solutions at prices that won’t break the bank. Our goal is to provide you with affordable options without sacrificing the quality of our services. From competitive pricing on installations to cost-effective maintenance plans, we ensure you get the best value for your money.
              </p>
              <h3 className="mt-8 text-xl font-semibold sm:text-left sm:text-2xl">Comprehensive, Tailored Services</h3>
              <p className="mt-5 mb-10 pb-10 border-b-2 text-base text-slate-400 sm:text-left lg:text-lg lg:text-justify">
                Our comprehensive range of services covers all your entertainment and security needs. We offer personalized solutions tailored to your specific requirements, ensuring that every aspect of your installation is handled with care. From initial consultation to final setup, we work closely with you to provide a solution that meets your expectations and enhances your space.
              </p>
              <h3 className="mt-8 text-xl font-semibold sm:text-left sm:text-2xl">Expert Installation by Professionals</h3>
              <p className="mt-5 mb-10 pb-10 border-b-2 text-base text-slate-400 sm:text-left lg:text-lg lg:text-justify">
                Our team of professionals brings years of experience and expertise to every installation. We use the latest tools and techniques to ensure that your systems are installed correctly and function flawlessly. Whether it’s setting up your DStv system, mounting your TV, or installing CCTV cameras, we deliver expert results with meticulous attention to detail.
              </p>
              <h3 className="mt-8 text-xl font-semibold sm:text-left sm:text-2xl">Exceptional Customer Service</h3>
              <p className="mt-5 mb-10 pb-10 border-b-2 text-base text-slate-400 sm:text-left lg:text-lg lg:text-justify">
                Our commitment to exceptional customer service is at the heart of everything we do. We prioritize your satisfaction and strive to provide a seamless experience from start to finish. Our team is always available to answer your questions, address your concerns, and provide support whenever you need it. We are dedicated to ensuring that every interaction with us is positive and productive.
              </p>
              <h3 className="mt-8 text-xl font-semibold sm:text-left sm:text-2xl">Custom Solutions for Every Need</h3>
              <p className="mt-5 mb-10 pb-10 text-base text-slate-400 sm:text-left lg:text-lg lg:text-justify">
                Every home and business has unique needs, and we offer custom solutions to meet those needs. Whether you require a specific TV mounting configuration or a tailored CCTV system, we provide solutions that fit your space and requirements perfectly. Our goal is to deliver a solution that enhances your environment and provides the functionality you need.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AboutDescription;
