import React, { useState } from 'react'
import Navbar from "../components/navbar";
import { Link } from "react-router-dom";

import Blogs from '../components/blogs'
import Client from '../components/client'
import Footer from '../components/footer';
import Switcher from '../components/switcher';
import {FiMapPin} from '../assets/icons/vander'

import {placeImage } from "../data/data";

import 'tiny-slider/dist/tiny-slider.css';
import "react-18-image-lightbox/style.css"
import { services } from '../data/data';

export default function Services(){
    let [setisOpen] = useState(false);
    let [currentImageIndex, setCurrentImageIndex] = useState(0);
    return(
        <>
        <Navbar navclass="defaultscroll is-sticky" navlight={true} manuclass="justify-end nav-light"/>
        <section className="relative table w-full items-center py-36 bg-[url('../../assets/images/bg/cta.jpg')] bg-top bg-no-repeat bg-cover">
            <div className="absolute inset-0 bg-gradient-to-b from-slate-900/60 via-slate-900/80 to-slate-900"></div>
            <div className="container relative">
                <div className="grid grid-cols-1 pb-8 text-center mt-10">
                    <h3 className="text-4xl leading-normal tracking-wider font-semibold text-white">CT DStv & CCTV Experts</h3>
                </div>
            </div>
            
            <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                <ul className="tracking-[0.5px] mb-0 inline-block">
                    <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white/50 hover:text-white"><Link to="/">CT DStv & CCTV Experts</Link></li>
                    <li className="inline-block text-base text-white/50 mx-0.5 ltr:rotate-0 rtl:rotate-180"><i className="mdi mdi-chevron-right"></i></li>
                    <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white" aria-current="page">About Us</li>
                </ul>
            </div>
        </section>

        <div className="container relative md:mt-24 mt-16">
            <div className="grid grid-cols-1 pb-8 text-center">
    <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Our Top Services</h3>

    <p className="text-slate-400 max-w-xl mx-auto">Need expert help with DStv, Openview, TV mounting, CCTV, or air conditioning? We offer top-notch installation and maintenance to ensure your home or business is running smoothly.</p>
</div>


                <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-6 gap-6">
                {services.slice(0,7).map((item,index)=>{
        return(
            <div className="group rounded-md shadow dark:shadow-gray-700" key={index}>
                <div className="relative overflow-hidden rounded-t-md shadow dark:shadow-gray-700 mx-3 mt-3">
                    <img src={item.image} className="scale-125 group-hover:scale-100 duration-500" alt=""/>
                    {item.tagText && (
                        <div className="absolute top-0 start-0 p-4">
                            <span className="bg-red-500 text-white text-[12px] px-2.5 py-1 font-medium rounded-md h-5">{item.tagText}</span>
                        </div>
                    )}
                    <div className="absolute top-0 end-0 p-4">
                        <Link to="#" className="size-8 inline-flex justify-center items-center bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 rounded-full text-slate-100 dark:text-slate-700 focus:text-red-500 dark:focus:text-red-500 hover:text-red-500 dark:hover:text-red-500"><i className="mdi mdi-heart text-[20px] align-middle"></i></Link>
                    </div>
                </div>
                <div className="p-4">
                    <p className="flex items-center text-slate-400 font-medium mb-2"><FiMapPin className="text-red-500 size-4 me-1"></FiMapPin> {item.place}</p>
                    <div className="flex items-center mt-2">
                        <span className="text-slate-400">Rating:</span>
                        <ul className="text-lg font-medium text-amber-400 list-none ms-2 space-x-1">
                            <li className="inline"><i className="mdi mdi-star align-middle"></i></li>
                            <li className="inline"><i className="mdi mdi-star align-middle"></i></li>
                            <li className="inline"><i className="mdi mdi-star align-middle"></i></li>
                            <li className="inline"><i className="mdi mdi-star align-middle"></i></li>
                            <li className="inline"><i className="mdi mdi-star align-middle"></i></li>
                            <li className="inline text-black dark:text-white text-sm">5.0(30)</li>
                        </ul>
                    </div>
                    <Link to={item.url} className="text-lg font-medium hover:text-red-500 duration-500 ease-in-out">{item.title}</Link>
                    <p><Link to={item.url} className="text-lg font-small hover:text-red-500 duration-500 ease-in-out">{item.description}</Link></p>
                    <div className="mt-4 pt-4 flex justify-between items-center border-t border-slate-100 dark:border-gray-800">
                        <Link to={item.url} className="text-slate-400 hover:text-red-500">Explore Now <i className="mdi mdi-arrow-right"></i></Link>
                    </div>
                </div>
            </div>
        )
    })}
                </div>
            </div>
        <section className="relative md:pb-24 pb-16">
            <Client/>

            <Blogs/>
        </section>
        <Footer/>
        <Switcher/>
        </>
    )
}