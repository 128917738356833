import React from "react";
import { Route, Routes } from "react-router-dom";
import './assets/css/tailwind.css'
import './assets/css/materialdesignicons.min.css'

import Index from './pages/index/index'
import IndexTwo from './pages/index/index-two'
import IndexThree from './pages/index/index-three'
import IndexFour from './pages/index/index-four'
import IndexFive from './pages/index/index-five'
import Grid from './pages/listing/tour-grid/grid' 
import GridLeftSidebar from './pages/listing/tour-grid/grid-left-sidebar'
import GridRightSidebar from './pages/listing/tour-grid/grid-right-sidebar'
import List from './pages/listing/tour-list/list'
import ListLeftSidebar from './pages/listing/tour-list/list-left-sidebar'
import ListRightSidebar from './pages/listing/tour-list/list-right-sidebar'
import TourDetailOne from './pages/listing/tour-detail/tour-detail-one'
import TourDetailTwo from './pages/listing/tour-detail/tour-detail-two'
import Aboutus from './pages/aboutus'
import UserProfile from './pages/account/user-profile'
import UserPayment from './pages/account/user-payment'
import UserInvoice from './pages/account/user-invoice'
import UserSocial from './pages/account/user-social'
import UserNotification from './pages/account/user-notification'
import UserSetting from './pages/account/user-setting'
import Helpcenter from './pages/helpcenter/helpcenter'
import HelpcenterFaq from './pages/helpcenter/helpcenter-faqs'
import Login from './pages/auth/login'
import Signup from './pages/auth/signup'
import SignupSuccess from './pages/auth/signup-success'
import ForgotPassword from './pages/auth/forgot-password'
import LockScreen from './pages/auth/lock-screen'
import Terms from './pages/utility/terms'
import Privacy from './pages/utility/privacy'
import Comingsoon from './pages/special/comingsoon'
import Maintenance from './pages/special/maintenance'
import Error from './pages/special/404'
import Blogs from './pages/blog/blogs'
import BlogStandard from './pages/blog/blog-standard'
import BlogDetail from './pages/blog/blog-detail'
import Contact from './pages/contact'
import Services from "./pages/services";
import DstvInstallation from "./pages/services/dstv-installation";
import OpenviewServices from "./pages/services/openview-services";
import TVMountingServices from "./pages/services/tv-mounting";
import CCTVInstallation from "./pages/services/cctv-installation";
import AirConditioningServices from "./pages/services/air-conditioning-services";
import OpenviewMaintenance from "./pages/services/openview-maintenance";
import DstvRepairs from "./pages/services/dstv-repairs";
import BlogDetail1 from "./pages/blog/top-tips-for-a-seamless-dstv-installation";
import BlogDetail2 from "./pages/blog/step-by-step installation,";
import BlogDetail5 from "./pages/blog/maintain-your-air-condition";
import BlogDetail4 from "./pages/blog/right-cctv-sytem";
import BlogDetail3 from "./pages/blog/tv-mounting";
import BlogDetail6 from "./pages/blog/common-dstv-issue";
import BlogDetail7 from "./pages/blog/maintaining-youropneview";
import BlogDetail8 from "./pages/blog/why-regular-maintenance-is-key";
import BlogDetail9 from "./pages/blog/the-ultimate-guide-to-home";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import avatar from "./avatar.jpg"
function App() {
  return (
    <>
   <Routes>
    <Route path="/" element={<Index/>}/>
    <Route path="/index-two" element={<IndexTwo/>}/>
    <Route path="/index-three" element={<IndexThree/>}/>
    <Route path="/index-four" element={<IndexFour/>}/>
    <Route path="/index-five" element={<IndexFive/>}/>
    <Route path="/grid" element={<Grid/>}/>
    <Route path="/grid-left-sidebar" element={<GridLeftSidebar/>}/>
    <Route path="/grid-right-sidebar" element={<GridRightSidebar/>}/>
    <Route path="/list" element={<List/>}/>
    <Route path="/list-left-sidebar" element={<ListLeftSidebar/>}/>
    <Route path="/list-right-sidebar" element={<ListRightSidebar/>}/>
    <Route path="/tour-detail-one" element={<TourDetailOne/>}/>
    <Route path="/tour-detail-one/:id" element={<TourDetailOne/>}/>
    <Route path="/tour-detail-two" element={<TourDetailTwo/>}/>
    <Route path="/aboutus" element={<Aboutus/>}/>
    <Route path="/user-profile" element={<UserProfile/>}/>
    <Route path="/user-payment" element={<UserPayment/>}/>
    <Route path="/user-invoice" element={<UserInvoice/>}/>
    <Route path="/user-social" element={<UserSocial/>}/>
    <Route path="/user-notification" element={<UserNotification/>}/>
    <Route path="/user-setting" element={<UserSetting/>}/>
    <Route path="/helpcenter" element={<Helpcenter/>}/>
    <Route path="/helpcenter-faqs" element={<HelpcenterFaq/>}/>
    <Route path="/login" element={<Login/>}/>
    <Route path="/signup" element={<Signup/>}/>
    <Route path="/signup-success" element={<SignupSuccess/>}/>
    <Route path="/forgot-password" element={<ForgotPassword/>}/>
    <Route path="/lock-screen" element={<LockScreen/>}/>
    <Route path="/terms" element={<Terms/>}/>
    <Route path="/privacy" element={<Privacy/>}/>
    <Route path="/comingsoon" element={<Comingsoon/>}/>
    <Route path="/maintenance" element={<Maintenance/>}/>
    <Route path="/404" element={<Error/>}/>
    <Route path="/blogs" element={<Blogs/>}/>
    <Route path="/blog-standard" element={<BlogStandard/>}/>
    <Route path="/blog-detail" element={<BlogDetail/>}/>
    <Route path="/blog-detail/:id" element={<BlogDetail/>}/>
    <Route path="/contact" element={<Contact/>}/>
    <Route path="/services" element={<Services/>}/>
    <Route path="/dstv-installation" element={<DstvInstallation />}/>
    <Route path="/openview-services" element={<OpenviewServices />}/>
    <Route path="/tv-mounting" element={<TVMountingServices />}/>
    <Route path="/cctv-installation" element={<CCTVInstallation />}/>
    <Route path="/air-conditioning-services" element={<AirConditioningServices />}/>
    <Route path="/dstv-repairs" element={<DstvRepairs />}/>
    <Route path="/openview-maintenance" element={<OpenviewMaintenance />}/>
    <Route path="/top-tips-for-a-seamless-dstv-installation" element={<BlogDetail1/>}/>
    <Route path="/step-by-step installation" element={<BlogDetail2/>}/>
    <Route path="/tv-mounting-blog" element={<BlogDetail3/>}/>
    <Route path="/right-cctv-sytem" element={<BlogDetail4/>}/>
    <Route path="/maintain-your-air-condition" element={<BlogDetail5/>}/>
    <Route path="/common-dstv-issue" element={<BlogDetail6/>}/>
    <Route path="/maintaining-youropneview" element={<BlogDetail7/>}/>
    <Route path="/why-regular-maintenance-is-key" element={<BlogDetail8/>}/>
    <Route path="/the-ultimate-guide-to-home" element={<BlogDetail9/>}/>
   </Routes>
   <FloatingWhatsApp
        phoneNumber="+27 694146198"
        accountName="Get a Quote"
        avatar={avatar}
        statusMessage="Typically replies within 1 hour" // Status message below the account name
        chatMessage="Hello there! 🤝.How can we help?" // Default message in chat box
        placeholder="Type a message..." // Input placeholder
        messageDelay={2} // Delay in seconds for the chat message to appear
        chatboxHeight={320} // Height of the chat box
        chatboxStyle={{ backgroundColor: "#f0f0f0", color: "#000" }} // Custom styles for the chatbox
        chatboxClassName="custom-chatbox-class" // Custom className for the chatbox
        notification={true} // Enable notifications
        notificationDelay={60} // Delay between notifications in seconds
        notificationLoop={0} // Number of times the notification will loop (0 for infinite)
        allowClickAway={true} // Close chatbox when clicked outside
        allowEsc={true} // Close chatbox when Escape key is pressed
        darkMode={true} // Enable dark mode
        style={{ bottom: '20px', right: '20px' }} // Position at bottom-right with custom styles
        className="custom-floating-whatsapp-class" 
        buttonStyle={{ backgroundColor: "#25D366", color: "#fff" }} 
        buttonClassName="custom-button-class"
        onClick={(event) => console.log("WhatsApp button clicked", event)}
        onSubmit={(event, formValue) => console.log("Message submitted", formValue)}
        onClose={() => console.log("Chatbox closed")}
        onNotification={() => console.log("Notification triggered")} 
        onLoopDone={() => console.log("Notification loop done")} 
      />
   </>
  );
}

export default App;
