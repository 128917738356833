import React, { useState, useEffect, useRef  } from 'react';
import { Link } from "react-router-dom";

import logoDark from "../assets/images/logo-dark.png"
import logoLight from "../assets/images/logo-light.png"
import logoWhite from "../assets/images/logo-white.png"

export default function Navbar({navclass, navlight, manuclass}){
    let [scrolling, setScrolling] = useState(false);
    let [isToggle, setToggle] = useState(false);
    let [manu , setManu] = useState('');
    let [subManu , setSubManu] = useState('');
    let [setIsOpen] = useState(false);
    let [setUserManu] = useState(false);

    let dropdownRef = useRef(null);
    let userRef = useRef(null)

    useEffect(()=>{
        const handleScroll = () => {
            const isScrolling = window.scrollY > 50;
            setScrolling(isScrolling);
        };

        const handleOutsideClick = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
              setIsOpen(false);
            }
          };

        const userOutsideClick = (e) =>{
            if(userRef.current && !userRef.current.contains(e.target)){
                setUserManu(false)
            }
        }

        window.addEventListener('scroll', handleScroll);
        window.addEventListener('click', handleOutsideClick);
        window.addEventListener('click', userOutsideClick);
        
        let current = window.location.pathname
        setManu(current)
        setSubManu(current)
        window.scrollTo(0, 0);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('click', handleOutsideClick);
            window.removeEventListener('click', userOutsideClick);
          };
    },[])

    const toggleMenu = () =>{
        setToggle(!isToggle)
      }

    return(
        <nav id="topnav" className={`${navclass} ${scrolling ? 'nav-sticky' : ''}`}>
            <div className="container relative">
                {navlight && (
                    <Link className="logo" to="/">
                        <span className="inline-block dark:hidden">
                            <img src={logoDark} className="h-12 l-dark" alt=""/>
                            <img src={logoLight} className="h-12 l-light" alt=""/>
                        </span>
                        <img src={logoWhite} className="hidden h-12 dark:inline-block" alt=""/>
                    </Link>
                )}
                {!navlight && (
                    <Link className="logo" to="/">
                        <div>
                            <img src={logoDark} className="h-12 inline-block dark:hidden" alt=""/>
                            <img src={logoWhite} className="h-12 hidden dark:inline-block" alt=""/>
                        </div>
                    </Link>
                )}

                <div className="menu-extras">
                    <div className="menu-item">
                        <Link to="#" className={`navbar-toggle ${isToggle ? 'open' : ''}`} id="isToggle" onClick={() =>toggleMenu()}>
                            <div className="lines">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </Link>
                    </div>
                </div>




<div
          id="navigation"
          style={{ display: isToggle === true ? "block" : "none" }}
        >
          <ul className={`navigation-menu ${manuclass}`}>
          <li className={`${manu === "/" ? "active" : ""}`}>
              <Link to="/" className="sub-menu-item">
                Home
              </Link>
            </li>
            <li className={`${manu === "/services" ? "active" : ""}`}>
              <Link to="/services" className="sub-menu-item">
                Services
              </Link>
            </li>

            <li
              className={`has-submenu parent-parent-menu-item ${["/page-item", "/aboutus", "/user-item", "/user-profile", "/user-billing", "/user-payment", "/user-invoice", "/user-social", "/user-notification", "/user-setting", "/help-item", "/helpcenter", "/helpcenter-faqs", "/helpcenter-guides", "/helpcenter-support", "/auth-item", "/login", "/signup", "/forgot-password", "/lock-screen", "/utility-item", "/terms", "/privacy", "/special-item", "/comingsoon", "/maintenance", "/404"].includes(manu) ? "active" : ""}`}
            >
              <Link
                to=""
                onClick={() =>
                  setSubManu(subManu === "/page-item" ? "" : "/page-item")
                }
              >
                Pages
              </Link>
              <span className="menu-arrow"></span>
              <ul
                className={`submenu ${["/page-item", "/aboutus", "/user-item", "/user-profile", "/user-billing", "/user-payment", "/user-invoice", "/user-social", "/user-notification", "/user-setting", "/help-item", "/helpcenter", "/helpcenter-faqs", "/helpcenter-guides", "/helpcenter-support", "/auth-item", "/login", "/signup", "/forgot-password", "/lock-screen", "/utility-item", "/terms", "/privacy", "/special-item", "/comingsoon", "/maintenance", "/404"].includes(subManu) ? "open" : ""}`}
              >
                <li className={`${manu === "/aboutus" ? "active" : ""}`}>
                  <Link to="/aboutus" className="sub-menu-item">
                    About Us
                  </Link>
                </li>

                <li
                  className={`has-submenu parent-menu-item ${["/help-item", "/helpcenter", "/helpcenter-faqs", "/helpcenter-guides", "/helpcenter-support"].includes(manu) ? "active" : ""}`}
                >
                  <Link
                    to=""
                    onClick={() =>
                      setSubManu(subManu === "/help-item" ? "" : "/help-item")
                    }
                  >
                    Helpcenter{" "}
                  </Link>
                  <span className="submenu-arrow"></span>
                  <ul
                    className={`submenu ${["/help-item", "/helpcenter", "/helpcenter-faqs", "/helpcenter-guides", "/helpcenter-support"].includes(subManu) ? "open" : ""}`}
                  >
                    <li className={`${manu === "/helpcenter" ? "active" : ""}`}>
                      <Link to="/helpcenter" className="sub-menu-item">
                        Overview
                      </Link>
                    </li>
                    <li
                      className={`${manu === "/helpcenter-faqs" ? "active" : ""}`}
                    >
                      <Link to="/helpcenter-faqs" className="sub-menu-item">
                        FAQs
                      </Link>
                    </li>
                  </ul>
                </li>

                <li
                  className={`has-submenu parent-menu-item ${["/utility-item", "/terms", "/privacy"].includes(manu) ? "active" : ""}`}
                >
                  <Link
                    to=""
                    onClick={() =>
                      setSubManu(
                        subManu === "/utility-item" ? "" : "/utility-item",
                      )
                    }
                  >
                    {" "}
                    Utility{" "}
                  </Link>
                  <span className="submenu-arrow"></span>
                  <ul
                    className={`submenu ${["/utility-item", "/terms", "/privacy"].includes(subManu) ? "open" : ""}`}
                  >
                    <li className={`${manu === "/terms" ? "active" : ""}`}>
                      <Link to="/terms" className="sub-menu-item">
                        Terms of Services
                      </Link>
                    </li>
                    <li className={`${manu === "/privacy" ? "active" : ""}`}>
                      <Link to="/privacy" className="sub-menu-item">
                        Privacy Policy
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>

            <li className={`${manu === "/blogs" ? "active" : ""}`}>
              <Link to="/blogs" className="sub-menu-item">
                Blogs
              </Link>
            </li>

            <li className={`${manu === "/contact" ? "active" : ""}`}>
              <Link to="/contact" className="sub-menu-item">
                Contact Us
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
