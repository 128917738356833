import React, { useState } from "react";
import { Link } from "react-router-dom";

import image1 from '../../assets/images/listing/1.jpg'
import image2 from '../../assets/images/listing/2.jpg'
import image3 from '../../assets/images/listing/3.jpg'
import image4 from '../../assets/images/listing/4.jpg'

import Navbar from "../../components/navbar";
import DetailSidebar from "../../components/detail-sidebar";
import Footer from "../../components/footer";
import Switcher from "../../components/switcher";
import { faqData } from "../../data/data";

import { FiCamera, FiChevronUp } from '../../assets/icons/vander'

import Lightbox from 'react-18-image-lightbox';
import 'react-18-image-lightbox/style.css';

export default function OpenviewMaintenance() {
    const [activeTab, setActiveTab] = useState(1);
    const [photoIndex, setActiveIndex] = useState(0);
    const [isOpen, setOpen] = useState(false);

    const images = [image1, image2, image3, image4];

    const handleClick = (index) => {
        setActiveIndex(index);
        setOpen(true);
    }

    return (
        <>
            <Navbar navclass="defaultscroll is-sticky" navlight={true} manuclass="justify-end nav-light" />
            <section className="relative table w-full items-center py-36 bg-[url('../../assets/images/bg/cta.jpg')] bg-top bg-no-repeat bg-cover">
                <div className="absolute inset-0 bg-gradient-to-b from-slate-900/60 via-slate-900/80 to-slate-900"></div>
                <div className="container relative">
                    <div className="grid grid-cols-1 pb-8 text-center mt-10">
                        <h3 className="text-3xl leading-normal tracking-wider font-semibold text-white">Openview Maintenance Services in Cape Town</h3>
                        <p className="text-white mt-4">Reliable Openview maintenance services to ensure optimal performance of your system. Our services cover routine checks, troubleshooting, and repairs to keep your Openview system running smoothly.</p>
                    </div>
                </div>
                <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                    <ul className="tracking-[0.5px] mb-0 inline-block">
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white/50 hover:text-white"><Link to="/">Home</Link></li>
                        <li className="inline-block text-base text-white/50 mx-0.5"><i className="mdi mdi-chevron-right"></i></li>
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white" aria-current="page">Openview Maintenance</li>
                    </ul>
                </div>
            </section>

            <section className="relative md:py-24 py-16">
                <div className="container relative">
                    <div className="grid md:grid-cols-12 grid-cols-1 gap-6">
                        <div className="lg:col-span-8 md:col-span-7">
                            <div className="grid grid-cols-12 gap-4">
                                <div className="md:col-span-8 col-span-7">
                                    <div className="group relative overflow-hidden rounded shadow dark:shadow-gray-800">
                                        <img src={image1} className="w-full lg:h-60 md:h-44 h-48 object-cover" alt="Openview Maintenance Service" />
                                        <div className="absolute inset-0 group-hover:bg-slate-900/70 duration-500 ease-in-out"></div>
                                        <div className="absolute top-1/2 -translate-y-1/2 start-0 end-0 text-center opacity-0 group-hover:opacity-100 duration-500">
                                            <Link to="#" onClick={() => handleClick(0)} className="inline-flex justify-center items-center size-9 bg-red-500 text-white rounded-full lightbox"><FiCamera className="size-4 align-middle"></FiCamera></Link>
                                        </div>
                                    </div>
                                </div>

                                <div className="md:col-span-4 col-span-5">
                                    <div className="group relative overflow-hidden rounded shadow dark:shadow-gray-800">
                                        <img src={image2} className="w-full lg:h-60 md:h-44 h-48 object-cover" alt="Openview Technician at work" />
                                        <div className="absolute inset-0 group-hover:bg-slate-900/70 duration-500 ease-in-out"></div>
                                        <div className="absolute top-1/2 -translate-y-1/2 start-0 end-0 text-center opacity-0 group-hover:opacity-100 duration-500">
                                            <Link to="#" onClick={() => handleClick(1)} className="inline-flex justify-center items-center size-9 bg-red-500 text-white rounded-full lightbox"><FiCamera className="size-4 align-middle"></FiCamera></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <h5 className="text-2xl font-semibold mt-5">Professional Openview Maintenance Services</h5>
                            <p className="text-slate-400 mt-2">
                                Our Openview maintenance services in Cape Town are designed to keep your system in top condition. From routine inspections to troubleshooting and repairs, our team ensures that your Openview system operates flawlessly.
                            </p>

                            <h5 className="text-lg font-semibold mt-6">Why Choose Our Openview Maintenance Services?</h5>
                            <ul className="list-disc ml-6 text-slate-400 mt-2">
                                <li><strong>Skilled and Experienced Technicians:</strong> Our team is trained and certified to handle all types of Openview maintenance issues.</li>
                                <li><strong>Thorough System Inspections:</strong> Regular inspections to ensure your system is performing at its best.</li>
                                <li><strong>Prompt Troubleshooting and Repairs:</strong> Fast and effective solutions to any problems that arise.</li>
                                <li><strong>Affordable Maintenance Plans:</strong> Competitive pricing and flexible maintenance plans to suit your needs.</li>
                            </ul>

                            <h5 className="text-lg font-semibold mt-6">Common Openview Maintenance Services:</h5>
                            <ul className="list-disc ml-6 text-slate-400 mt-2">
                                <li><strong>System Diagnostics and Troubleshooting:</strong> Identify and fix issues affecting your Openview system.</li>
                                <li><strong>Signal Strength and Quality Checks:</strong> Ensure optimal signal reception and quality.</li>
                                <li><strong>Firmware Updates and Configuration:</strong> Keep your system up-to-date with the latest firmware and settings.</li>
                                <li><strong>Hardware Repairs and Replacements:</strong> Address any hardware issues and replace faulty components.</li>
                                <li><strong>Regular Maintenance Plans:</strong> Scheduled maintenance to prevent issues and extend the lifespan of your system.</li>
                            </ul>

                            <h5 className="text-lg font-semibold mt-6">Frequently Asked Questions:</h5>
                            <div className="mt-6">
                                {faqData.slice(0, 4).map((item, index) => {
                                  
                                    return (
                                        <div className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4 first:mt-0" key={index}>
                                            <h2 className="text-base font-semibold">
                                                <button type="button" className={`flex justify-between items-center p-5 w-full font-medium text-start ${activeTab === item.id ? 'bg-gray-50 dark:bg-slate-800 text-red-500' : ''}`} onClick={() => setActiveTab(item.id)}>
                                                    <span>{item.title}</span>
                                                    <FiChevronUp className={`size-4 shrink-0 ${activeTab === item.id ? '' : 'rotate-180'}`} />
                                                </button>
                                            </h2>
                                            <div className={activeTab === item.id ? "" : "hidden"}>
                                                <div className="p-5">
                                                    <p className="text-slate-400 dark:text-gray-400">{item.desc}</p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>

                        
                    </div>
                </div>
            </section>

            {isOpen && (
                <Lightbox
                    mainSrc={images[photoIndex]}
                    nextSrc={images[(photoIndex + 1) % images.length]}
                    prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                    onCloseRequest={() => setOpen(false)}
                    onMovePrevRequest={() =>
                        setActiveIndex((photoIndex + images.length - 1) % images.length)
                    }
                    onMoveNextRequest={() =>
                        setActiveIndex((photoIndex + 1) % images.length)
                    }
                />
            )}

            <Footer />

            <Switcher />
        </>
    );
}
