import React from "react";
import { Link } from "react-router-dom";

import Navbar from "../../components/navbar";
import Blogs from '../../components/blogs';
import BlogSidebar from "../../components/blog-sidebar";
import Footer from "../../components/footer";
import Switcher from "../../components/switcher";

import blogImg from "../../assets/images/blog/7.jpg"; // Update the image source as needed

export default function BlogDetail3() {
    return (
        <>
            <Navbar navclass="defaultscroll is-sticky" navlight={true} manuclass="justify-end nav-light" />
            <section className="relative table w-full items-center py-36 bg-[url('../../assets/images/bg/cta.jpg')] bg-top bg-no-repeat bg-cover">
                <div className="absolute inset-0 bg-gradient-to-b from-slate-900/60 via-slate-900/80 to-slate-900"></div>
                <div className="container relative">
                    <div className="grid grid-cols-1 pb-8 text-center mt-10">
                        <h3 className="text-4xl leading-normal tracking-wider font-semibold text-white">Everything You Need to Know About TV Mounting</h3>
                        <ul className="list-none mt-6">
                            <li className="inline-block text-white/50 mx-5"> <span className="text-white block">Author :</span> <span className="block">CT DStv & CCTV Experts Team</span></li>
                            <li className="inline-block text-white/50 mx-5"> <span className="text-white block">Date :</span> <span className="block">21st August 2024</span></li>
                            <li className="inline-block text-white/50 mx-5"> <span className="text-white block">Time :</span> <span className="block">7 Min Read</span></li>
                        </ul>
                    </div>
                </div>
                
                <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                    <ul className="tracking-[0.5px] mb-0 inline-block">
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white/50 hover:text-white"><Link to="/">CT DStv & CCTV Experts</Link></li>
                        <li className="inline-block text-base text-white/50 mx-0.5 ltr:rotate-0 rtl:rotate-180"><i className="mdi mdi-chevron-right"></i></li>
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white" aria-current="page">Blog Detail</li>
                    </ul>
                </div>
            </section>

            <section className="relative md:py-24 py-16">
                <div className="container">
                    <div className="grid md:grid-cols-12 grid-cols-1 gap-6">
                        <div className="lg:col-span-8 md:col-span-6">
                            <div className="relative overflow-hidden rounded-md shadow dark:shadow-gray-800">
                                <img src={blogImg} alt="TV Mounting Setup" />
                                <div className="p-6">
                                    <h4 className="text-xl font-semibold mb-4">Introduction to TV Mounting</h4>
                                    <p className="text-slate-400 mb-4">Mounting your TV can transform the look of your room and improve your viewing experience. Proper TV mounting ensures that your TV is secure and positioned optimally for both comfort and aesthetics. This guide covers everything you need to know about mounting your TV, from choosing the right mount to installation tips and troubleshooting.</p>

                                    <h4 className="text-xl font-semibold mb-4">Benefits of Mounting Your TV</h4>
                                    <p className="text-slate-400 mb-4">Mounting your TV on the wall offers several benefits:
                                    <ul className="list-disc pl-5 mt-2">
                                        <li>Improved Aesthetics: A mounted TV eliminates clutter and provides a sleek, modern look.</li>
                                        <li>Space Saving: Free up floor space by moving the TV off furniture.</li>
                                        <li>Optimal Viewing Height: Position your TV at eye level for a more comfortable viewing experience.</li>
                                        <li>Reduced Cable Clutter: Conceal cables and wires for a cleaner appearance.</li>
                                    </ul>
                                    </p>

                                    <h4 className="text-xl font-semibold mb-4">Choosing the Right TV Mount</h4>
                                    <p className="text-slate-400 mb-4">Selecting the appropriate TV mount is crucial for a safe and effective installation. Consider the following factors:
                                    <ul className="list-disc pl-5 mt-2">
                                        <li>TV Size and Weight: Ensure the mount supports your TV's size and weight.</li>
                                        <li>Mount Type: Choose between fixed, tilting, or full-motion mounts based on your needs.</li>
                                        <li>VESA Pattern: Check that the mount is compatible with your TV's VESA pattern.</li>
                                    </ul>
                                    </p>

                                    <h4 className="text-xl font-semibold mb-4">Step-by-Step Installation Guide</h4>
                                    <p className="text-slate-400 mb-4">Follow these steps for a successful TV mounting installation:
                                    <ol className="list-decimal pl-5 mt-2">
                                        <li>Select the Mounting Location: Choose a wall that can support the weight of the TV and mount.</li>
                                        <li>Find Studs: Use a stud finder to locate wall studs for secure mounting.</li>
                                        <li>Attach the Mounting Bracket: Secure the bracket to the wall using screws and anchors if needed.</li>
                                        <li>Attach the TV to the Mount: Secure the TV to the bracket according to the mount's instructions.</li>
                                        <li>Adjust and Level: Ensure the TV is level and make any necessary adjustments.</li>
                                    </ol>
                                    </p>

                                    <h4 className="text-xl font-semibold mb-4">Common Issues and Troubleshooting</h4>
                                    <p className="text-slate-400 mb-4">If you encounter problems during installation, consider the following solutions:
                                    <ul className="list-disc pl-5 mt-2">
                                        <li>Mount is Not Level: Check that the mount is properly aligned and adjust as needed.</li>
                                        <li>TV is Loose: Ensure all screws and bolts are tightly secured.</li>
                                        <li>Cables are Visible: Use cable management solutions to conceal cables.</li>
                                    </ul>
                                    </p>

                                    <h4 className="text-xl font-semibold mb-4">FAQs about TV Mounting</h4>
                                    <p className="text-slate-400 mb-4">Q: How high should I mount my TV?  
                                    A: Mount your TV at eye level when seated for the best viewing experience.</p>
                                    <p className="text-slate-400 mb-4">Q: Can I mount a TV on drywall alone?  
                                    A: It's recommended to mount the TV to wall studs for maximum stability. Use anchors if mounting on drywall alone.</p>
                                    <p className="text-slate-400 mb-4">Q: How do I hide the cables?  
                                    A: Use cable management systems or covers to conceal and organize cables.</p>

                                    <p className="text-slate-400 italic border-x-4 border-red-500 rounded-ss-xl rounded-ee-xl mt-3 p-3">" For professional TV mounting services and expert advice, trust CT DStv & CCTV Experts. We ensure your TV is mounted securely and perfectly positioned for the best viewing experience. "</p>
                                    <p className="text-slate-400 mt-3">Need help with TV mounting or other home technology services? Contact us today for expert assistance and quality service.</p>
                                </div>
                            </div>
                        </div>

                        <BlogSidebar />
                    </div>
                </div>

                <Blogs />
            </section>
            <Footer />
            <Switcher />
        </>
    )
}
