import React from "react";
import { Link } from "react-router-dom";

import Navbar from "../../components/navbar";
import Blogs from '../../components/blogs';
import BlogSidebar from "../../components/blog-sidebar";
import Footer from "../../components/footer";
import Switcher from "../../components/switcher";

import blogImg from "../../assets/images/blog/8.jpg"; // Update the image source as needed

export default function BlogDetail2() {
    return (
        <>
            <Navbar navclass="defaultscroll is-sticky" navlight={true} manuclass="justify-end nav-light" />
            <section className="relative table w-full items-center py-36 bg-[url('../../assets/images/bg/cta.jpg')] bg-top bg-no-repeat bg-cover">
                <div className="absolute inset-0 bg-gradient-to-b from-slate-900/60 via-slate-900/80 to-slate-900"></div>
                <div className="container relative">
                    <div className="grid grid-cols-1 pb-8 text-center mt-10">
                        <h3 className="text-4xl leading-normal tracking-wider font-semibold text-white">Setting Up Openview: A Comprehensive Guide</h3>
                        <ul className="list-none mt-6">
                            <li className="inline-block text-white/50 mx-5"> <span className="text-white block">Author :</span> <span className="block">CT DStv & CCTV Experts Team</span></li>
                            <li className="inline-block text-white/50 mx-5"> <span className="text-white block">Date :</span> <span className="block">20th August 2024</span></li>
                            <li className="inline-block text-white/50 mx-5"> <span className="text-white block">Time :</span> <span className="block">6 Min Read</span></li>
                        </ul>
                    </div>
                </div>
                
                <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                    <ul className="tracking-[0.5px] mb-0 inline-block">
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white/50 hover:text-white"><Link to="/">CT DStv & CCTV Experts</Link></li>
                        <li className="inline-block text-base text-white/50 mx-0.5 ltr:rotate-0 rtl:rotate-180"><i className="mdi mdi-chevron-right"></i></li>
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white" aria-current="page">Blog Detail</li>
                    </ul>
                </div>
            </section>

            <section className="relative md:py-24 py-16">
                <div className="container">
                    <div className="grid md:grid-cols-12 grid-cols-1 gap-6">
                        <div className="lg:col-span-8 md:col-span-6">
                            <div className="relative overflow-hidden rounded-md shadow dark:shadow-gray-800">
                                <img src={blogImg} alt="Openview Installation Setup" />
                                <div className="p-6">
                                    <h4 className="text-xl font-semibold mb-4">Introduction to Openview</h4>
                                    <p className="text-slate-400 mb-4">Openview provides a range of free-to-air channels that are perfect for those looking to cut costs without sacrificing content quality. Setting up Openview is straightforward, but it's important to follow the correct steps to ensure optimal performance. In this guide, we'll cover everything you need to know for a successful Openview setup.</p>

                                    <h4 className="text-xl font-semibold mb-4">Benefits of Openview</h4>
                                    <p className="text-slate-400 mb-4">Openview offers a variety of channels at no cost, making it a budget-friendly alternative to traditional pay-TV services. It provides access to a broad range of local and international content, including news, sports, and entertainment. Additionally, the installation process is relatively simple and can be done quickly.</p>

                                    <h4 className="text-xl font-semibold mb-4">Required Equipment</h4>
                                    <p className="text-slate-400 mb-4">To set up Openview, you will need:
                                    <ul className="list-disc pl-5 mt-2">
                                        <li>Openview decoder</li>
                                        <li>Satellite dish</li>
                                        <li>Coaxial cables</li>
                                        <li>Power supply unit</li>
                                        <li>Remote control</li>
                                    </ul>
                                    </p>

                                    <h4 className="text-xl font-semibold mb-4">Step-by-Step Installation Process</h4>
                                    <p className="text-slate-400 mb-4">1. Position the Satellite Dish: Install the satellite dish in a location with a clear line of sight to the satellite. Use a sturdy mount to ensure stability.
                                    2. Connect the Dish to the Decoder: Attach the coaxial cable from the satellite dish to the Openview decoder. Ensure that all connections are secure.
                                    3. Power Up the System: Connect the decoder to a power source and turn it on. Follow the on-screen instructions to complete the initial setup.
                                    4. Scan for Channels: Use the decoder's menu to scan for available channels. The system will automatically search and save the channels for you.
                                    5. Adjust Settings: Fine-tune the settings for optimal picture and sound quality. Refer to the user manual for detailed instructions on configuration.</p>

                                    <h4 className="text-xl font-semibold mb-4">Troubleshooting Common Issues</h4>
                                    <p className="text-slate-400 mb-4">If you encounter problems during or after installation:
                                    <ul className="list-disc pl-5 mt-2">
                                        <li>Check all cable connections and ensure they are secure.</li>
                                        <li>Verify that the satellite dish is properly aligned.</li>
                                        <li>Ensure there are no obstructions blocking the satellite signal.</li>
                                        <li>Restart the decoder and perform a new channel scan.</li>
                                    </ul>
                                    </p>

                                    <h4 className="text-xl font-semibold mb-4">FAQs about Openview Setup</h4>
                                    <p className="text-slate-400 mb-4">Q: How long does the installation process take?  
                                    A: The installation process typically takes around 1-2 hours, depending on your location and the complexity of the setup.</p>
                                    <p className="text-slate-400 mb-4">Q: Can I install Openview myself?  
                                    A: Yes, Openview installation is designed to be user-friendly. However, if you encounter any issues, professional assistance is available.</p>
                                    <p className="text-slate-400 mb-4">Q: What should I do if I experience signal loss?  
                                    A: Check the satellite dish alignment and cable connections. If the issue persists, contact Openview support for assistance.</p>
                                    
                                    <p className="text-slate-400 italic border-x-4 border-red-500 rounded-ss-xl rounded-ee-xl mt-3 p-3">" For a flawless Openview setup and ongoing support, rely on CT DStv & CCTV Experts. We ensure that your viewing experience is uninterrupted and enjoyable. "</p>
                                    <p className="text-slate-400 mt-3">Need help with your Openview setup or other home technology services? Contact us today for professional assistance and quality service.</p>
                                </div>
                            </div>
                        </div>

                        <BlogSidebar />
                    </div>
                </div>

                <Blogs />
            </section>
            <Footer />
            <Switcher />
        </>
    )
}
