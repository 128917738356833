import React from "react";
import { Link } from "react-router-dom";

import Navbar from "../../components/navbar";
import Blogs from '../../components/blogs';
import BlogSidebar from "../../components/blog-sidebar";
import Footer from "../../components/footer";
import Switcher from "../../components/switcher";

import blogImg from "../../assets/images/blog/5.jpg"; // Update the image source as needed

export default function BlogDetail5() {
    return (
        <>
            <Navbar navclass="defaultscroll is-sticky" navlight={true} manuclass="justify-end nav-light" />
            <section className="relative table w-full items-center py-36 bg-[url('../../assets/images/bg/cta.jpg')] bg-top bg-no-repeat bg-cover">
                <div className="absolute inset-0 bg-gradient-to-b from-slate-900/60 via-slate-900/80 to-slate-900"></div>
                <div className="container relative">
                    <div className="grid grid-cols-1 pb-8 text-center mt-10">
                        <h3 className="text-4xl leading-normal tracking-wider font-semibold text-white">How to Maintain Your Air Conditioning System</h3>
                        <ul className="list-none mt-6">
                            <li className="inline-block text-white/50 mx-5"> <span className="text-white block">Author :</span> <span className="block">CT DStv & CCTV Experts Team</span></li>
                            <li className="inline-block text-white/50 mx-5"> <span className="text-white block">Date :</span> <span className="block">25th August 2024</span></li>
                            <li className="inline-block text-white/50 mx-5"> <span className="text-white block">Time :</span> <span className="block">7 Min Read</span></li>
                        </ul>
                    </div>
                </div>
                
                <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                    <ul className="tracking-[0.5px] mb-0 inline-block">
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white/50 hover:text-white"><Link to="/">CT DStv & CCTV Experts</Link></li>
                        <li className="inline-block text-base text-white/50 mx-0.5 ltr:rotate-0 rtl:rotate-180"><i className="mdi mdi-chevron-right"></i></li>
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white" aria-current="page">Blog Detail</li>
                    </ul>
                </div>
            </section>

            <section className="relative md:py-24 py-16">
                <div className="container">
                    <div className="grid md:grid-cols-12 grid-cols-1 gap-6">
                        <div className="lg:col-span-8 md:col-span-6">
                            <div className="relative overflow-hidden rounded-md shadow dark:shadow-gray-800">
                                <img src={blogImg} alt="Maintaining Air Conditioning System" />
                                <div className="p-6">
                                    <h4 className="text-xl font-semibold mb-4">Why Regular Maintenance is Important</h4>
                                    <p className="text-slate-400 mb-4">Maintaining your air conditioning system is crucial for ensuring its efficiency and longevity. Regular maintenance helps prevent unexpected breakdowns, improves air quality, and reduces energy consumption. Here’s a comprehensive guide on how to keep your AC system in top condition.</p>

                                    <h4 className="text-xl font-semibold mb-4">Essential Maintenance Tasks</h4>
                                    <p className="text-slate-400 mb-4">Perform these key maintenance tasks to ensure your air conditioning system runs smoothly:
                                    <ul className="list-disc pl-5 mt-2">
                                        <li>Clean or Replace Air Filters: Dirty filters can reduce airflow and efficiency. Check and clean or replace filters every 1-3 months.</li>
                                        <li>Inspect and Clean Coils: Both evaporator and condenser coils can accumulate dirt over time. Clean them to maintain efficient heat exchange.</li>
                                        <li>Check Refrigerant Levels: Low refrigerant levels can indicate a leak. Ensure proper levels to maintain cooling efficiency.</li>
                                        <li>Inspect Ductwork: Look for leaks or damage in your ductwork that could affect airflow and efficiency. Seal any leaks promptly.</li>
                                        <li>Clean the Condensate Drain: Ensure the condensate drain is clear to prevent water damage and mold growth.</li>
                                        <li>Check Thermostat Settings: Verify that the thermostat is working correctly and set to the desired temperature.</li>
                                    </ul>
                                    </p>

                                    <h4 className="text-xl font-semibold mb-4">Seasonal Maintenance Tips</h4>
                                    <p className="text-slate-400 mb-4">Follow these seasonal maintenance tips to prepare your AC system for peak performance throughout the year:
                                    <ul className="list-disc pl-5 mt-2">
                                        <li>Before Summer: Schedule a professional inspection to ensure your system is ready for the hot months ahead.</li>
                                        <li>Before Winter: If you use a heat pump, have it inspected to ensure it’s functioning correctly for the cooler months.</li>
                                    </ul>
                                    </p>

                                    <h4 className="text-xl font-semibold mb-4">Common AC Problems and Solutions</h4>
                                    <p className="text-slate-400 mb-4">Address these common issues to keep your air conditioning system running smoothly:
                                    <ul className="list-disc pl-5 mt-2">
                                        <li>System Not Cooling: Check for dirty filters, refrigerant leaks, or malfunctioning components.</li>
                                        <li>Strange Noises: Unusual sounds may indicate loose or damaged parts that need attention.</li>
                                        <li>Weak Airflow: Inspect filters and ductwork for blockages or leaks affecting airflow.</li>
                                    </ul>
                                    </p>

                                    <h4 className="text-xl font-semibold mb-4">When to Call a Professional</h4>
                                    <p className="text-slate-400 mb-4">While regular maintenance can be performed by homeowners, some tasks and issues require professional expertise:
                                    <ul className="list-disc pl-5 mt-2">
                                        <li>Complex Repairs: For major repairs or component replacements, consult a licensed technician.</li>
                                        <li>System Inspection: Schedule annual inspections with a professional to catch potential issues early.</li>
                                    </ul>
                                    </p>

                                    <h4 className="text-xl font-semibold mb-4">FAQs about Air Conditioning Maintenance</h4>
                                    <p className="text-slate-400 mb-4">Q: How often should I clean or replace my air filters?  
                                    A: Clean or replace filters every 1-3 months, depending on usage and environmental factors.</p>
                                    <p className="text-slate-400 mb-4">Q: Can I perform all maintenance tasks myself?  
                                    A: While many tasks can be done by homeowners, some require professional expertise for optimal results.</p>
                                    <p className="text-slate-400 mb-4">Q: How can I improve my AC system’s energy efficiency?  
                                    A: Regular maintenance, sealing leaks, and using a programmable thermostat can improve efficiency and reduce energy bills.</p>

                                    <p className="text-slate-400 italic border-x-4 border-red-500 rounded-ss-xl rounded-ee-xl mt-3 p-3">" For professional air conditioning maintenance and repairs, trust CT DStv & CCTV Experts. Our team ensures your AC system operates efficiently and reliably throughout the year. "</p>
                                    <p className="text-slate-400 mt-3">Contact us today for expert assistance and comprehensive air conditioning services tailored to your needs.</p>
                                </div>
                            </div>
                        </div>

                        <BlogSidebar />
                    </div>
                </div>

                <Blogs />
            </section>
            <Footer />
            <Switcher />
        </>
    )
}
