import React from "react";
import { Link } from "react-router-dom";

import Navbar from "../../components/navbar";
import Blogs from '../../components/blogs';
import BlogSidebar from "../../components/blog-sidebar";
import Footer from "../../components/footer";
import Switcher from "../../components/switcher";

import blogImg from "../../assets/images/blog/8.jpg"; // Update the image source as needed

export default function BlogDetail4() {
    return (
        <>
            <Navbar navclass="defaultscroll is-sticky" navlight={true} manuclass="justify-end nav-light" />
            <section className="relative table w-full items-center py-36 bg-[url('../../assets/images/bg/cta.jpg')] bg-top bg-no-repeat bg-cover">
                <div className="absolute inset-0 bg-gradient-to-b from-slate-900/60 via-slate-900/80 to-slate-900"></div>
                <div className="container relative">
                    <div className="grid grid-cols-1 pb-8 text-center mt-10">
                        <h3 className="text-4xl leading-normal tracking-wider font-semibold text-white">Choosing the Right CCTV System for Your Home</h3>
                        <ul className="list-none mt-6">
                            <li className="inline-block text-white/50 mx-5"> <span className="text-white block">Author :</span> <span className="block">CT DStv & CCTV Experts Team</span></li>
                            <li className="inline-block text-white/50 mx-5"> <span className="text-white block">Date :</span> <span className="block">23rd August 2024</span></li>
                            <li className="inline-block text-white/50 mx-5"> <span className="text-white block">Time :</span> <span className="block">8 Min Read</span></li>
                        </ul>
                    </div>
                </div>
                
                <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                    <ul className="tracking-[0.5px] mb-0 inline-block">
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white/50 hover:text-white"><Link to="/">CT DStv & CCTV Experts</Link></li>
                        <li className="inline-block text-base text-white/50 mx-0.5 ltr:rotate-0 rtl:rotate-180"><i className="mdi mdi-chevron-right"></i></li>
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white" aria-current="page">Blog Detail</li>
                    </ul>
                </div>
            </section>

            <section className="relative md:py-24 py-16">
                <div className="container">
                    <div className="grid md:grid-cols-12 grid-cols-1 gap-6">
                        <div className="lg:col-span-8 md:col-span-6">
                            <div className="relative overflow-hidden rounded-md shadow dark:shadow-gray-800">
                                <img src={blogImg} alt="Choosing the Right CCTV System" />
                                <div className="p-6">
                                    <h4 className="text-xl font-semibold mb-4">Why You Need a CCTV System</h4>
                                    <p className="text-slate-400 mb-4">A CCTV (Closed-Circuit Television) system is a critical component of home security, providing real-time surveillance and recording footage to deter and detect criminal activity. Choosing the right CCTV system ensures you have the best protection tailored to your specific needs. This guide will help you navigate through the essential factors to consider when selecting a CCTV system for your home.</p>

                                    <h4 className="text-xl font-semibold mb-4">Key Factors to Consider</h4>
                                    <p className="text-slate-400 mb-4">When selecting a CCTV system, keep the following factors in mind:
                                    <ul className="list-disc pl-5 mt-2">
                                        <li>Camera Types: Choose between bullet, dome, and PTZ (Pan-Tilt-Zoom) cameras based on your surveillance needs.</li>
                                        <li>Resolution: Higher resolution cameras provide clearer images and better identification of individuals.</li>
                                        <li>Night Vision: Ensure your cameras have infrared capabilities for clear images in low light conditions.</li>
                                        <li>Storage Options: Decide between local storage (e.g., DVR/NVR) and cloud storage based on your preference for data security and accessibility.</li>
                                        <li>Field of View: Consider the camera's field of view to cover more area with fewer cameras.</li>
                                        <li>Remote Access: Look for systems that offer remote access via smartphone or computer for real-time monitoring.</li>
                                    </ul>
                                    </p>

                                    <h4 className="text-xl font-semibold mb-4">Choosing the Right Camera for Your Needs</h4>
                                    <p className="text-slate-400 mb-4">Different camera types serve different purposes:
                                    <ul className="list-disc pl-5 mt-2">
                                        <li>Bullet Cameras: Ideal for outdoor use with a long-range view and deterrent effect.</li>
                                        <li>Dome Cameras: Suitable for indoor use, offering a discreet appearance and wider field of view.</li>
                                        <li>PTZ Cameras: Allow remote control to pan, tilt, and zoom, providing flexibility to monitor various areas.</li>
                                    </ul>
                                    </p>

                                    <h4 className="text-xl font-semibold mb-4">Installation Tips</h4>
                                    <p className="text-slate-400 mb-4">Proper installation is key to an effective CCTV system:
                                    <ol className="list-decimal pl-5 mt-2">
                                        <li>Strategic Placement: Install cameras at entry points and areas with high foot traffic for maximum coverage.</li>
                                        <li>Height and Angle: Position cameras at an optimal height and angle to capture clear footage without obstructions.</li>
                                        <li>Wiring and Power: Ensure proper wiring and power supply to avoid disruptions and maintain camera functionality.</li>
                                    </ol>
                                    </p>

                                    <h4 className="text-xl font-semibold mb-4">Maintaining Your CCTV System</h4>
                                    <p className="text-slate-400 mb-4">Regular maintenance is crucial for optimal performance:
                                    <ul className="list-disc pl-5 mt-2">
                                        <li>Check Camera Lenses: Clean camera lenses periodically to ensure clear image quality.</li>
                                        <li>Review Footage: Regularly review recorded footage to ensure the system is capturing all necessary areas.</li>
                                        <li>Update Software: Keep your system's software and firmware up-to-date for security and performance enhancements.</li>
                                    </ul>
                                    </p>

                                    <h4 className="text-xl font-semibold mb-4">FAQs about CCTV Systems</h4>
                                    <p className="text-slate-400 mb-4">Q: How many cameras do I need for my home?  
                                    A: The number of cameras needed depends on the size of your property and specific security concerns. Commonly, 4-8 cameras are sufficient for an average home.</p>
                                    <p className="text-slate-400 mb-4">Q: Can I install a CCTV system myself?  
                                    A: While DIY installation is possible, professional installation ensures optimal placement and functionality.</p>
                                    <p className="text-slate-400 mb-4">Q: How long can CCTV footage be stored?  
                                    A: Storage duration depends on the storage capacity and settings of your system. Typically, footage is stored for 1-3 weeks before overwriting.</p>

                                    <p className="text-slate-400 italic border-x-4 border-red-500 rounded-ss-xl rounded-ee-xl mt-3 p-3">" For expert advice and installation of the best CCTV system for your home, trust CT DStv & CCTV Experts. Our team provides tailored solutions to ensure your property is secure and well-monitored. "</p>
                                    <p className="text-slate-400 mt-3">Need help with selecting or installing a CCTV system? Contact us today for professional assistance and high-quality service.</p>
                                </div>
                            </div>
                        </div>

                        <BlogSidebar />
                    </div>
                </div>

                <Blogs />
            </section>
            <Footer />
            <Switcher />
        </>
    )
}
