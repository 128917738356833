import React from "react";
import { Link } from "react-router-dom";

import Navbar from "../../components/navbar";
import Blogs from '../../components/blogs';
import BlogSidebar from "../../components/blog-sidebar";
import Footer from "../../components/footer";
import Switcher from "../../components/switcher";

import blogImg from "../../assets/images/blog/6.jpg"; // Update the image source as needed

export default function BlogDetail6() {
    return (
        <>
            <Navbar navclass="defaultscroll is-sticky" navlight={true} manuclass="justify-end nav-light" />
            <section className="relative table w-full items-center py-36 bg-[url('../../assets/images/bg/cta.jpg')] bg-top bg-no-repeat bg-cover">
                <div className="absolute inset-0 bg-gradient-to-b from-slate-900/60 via-slate-900/80 to-slate-900"></div>
                <div className="container relative">
                    <div className="grid grid-cols-1 pb-8 text-center mt-10">
                        <h3 className="text-4xl leading-normal tracking-wider font-semibold text-white">Common DStv Issues and How to Fix Them</h3>
                        <ul className="list-none mt-6">
                            <li className="inline-block text-white/50 mx-5"> <span className="text-white block">Author :</span> <span className="block">CT DStv & CCTV Experts Team</span></li>
                            <li className="inline-block text-white/50 mx-5"> <span className="text-white block">Date :</span> <span className="block">30th August 2024</span></li>
                            <li className="inline-block text-white/50 mx-5"> <span className="text-white block">Time :</span> <span className="block">6 Min Read</span></li>
                        </ul>
                    </div>
                </div>
                
                <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                    <ul className="tracking-[0.5px] mb-0 inline-block">
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white/50 hover:text-white"><Link to="/">CT DStv & CCTV Experts</Link></li>
                        <li className="inline-block text-base text-white/50 mx-0.5 ltr:rotate-0 rtl:rotate-180"><i className="mdi mdi-chevron-right"></i></li>
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white" aria-current="page">Blog Detail</li>
                    </ul>
                </div>
            </section>

            <section className="relative md:py-24 py-16">
                <div className="container">
                    <div className="grid md:grid-cols-12 grid-cols-1 gap-6">
                        <div className="lg:col-span-8 md:col-span-6">
                            <div className="relative overflow-hidden rounded-md shadow dark:shadow-gray-800">
                                <img src={blogImg} alt="DStv Troubleshooting" />
                                <div className="p-6">
                                    <h4 className="text-xl font-semibold mb-4">Common DStv Issues and Their Solutions</h4>
                                    <p className="text-slate-400 mb-4">DStv is a popular satellite television service, but like any technology, it can sometimes encounter issues. In this guide, we’ll explore some common problems users face with their DStv services and provide practical solutions to resolve them.</p>

                                    <h4 className="text-xl font-semibold mb-4">1. Poor Signal Quality</h4>
                                    <p className="text-slate-400 mb-4">If you experience poor signal quality, you might notice pixelation, freezing, or signal loss. Here are some steps to address this issue:</p>
                                    <ul className="list-disc pl-5 mt-2">
                                        <li>Check the Satellite Dish Alignment: Ensure that the dish is correctly aligned with the satellite. If it's been moved or shifted, realign it for optimal signal reception.</li>
                                        <li>Inspect Cables and Connections: Look for loose or damaged cables. Tighten connections and replace any faulty cables.</li>
                                        <li>Clear Obstructions: Ensure there are no obstructions blocking the satellite dish’s line of sight, such as branches or buildings.</li>
                                    </ul>

                                    <h4 className="text-xl font-semibold mb-4">2. No Signal</h4>
                                    <p className="text-slate-400 mb-4">If your DStv decoder displays a “No Signal” message, follow these troubleshooting steps:</p>
                                    <ul className="list-disc pl-5 mt-2">
                                        <li>Check the Satellite Dish and LNB: Make sure the dish and LNB (Low Noise Block converter) are securely mounted and properly connected.</li>
                                        <li>Reset the Decoder: Turn off the decoder, wait for a few minutes, then turn it back on. Sometimes a simple reset can resolve the issue.</li>
                                        <li>Inspect Power Supply: Ensure the power supply is working correctly and providing consistent power to the decoder.</li>
                                    </ul>

                                    <h4 className="text-xl font-semibold mb-4">3. Channels Not Showing</h4>
                                    <p className="text-slate-400 mb-4">If some channels are missing or not displaying, try the following solutions:</p>
                                    <ul className="list-disc pl-5 mt-2">
                                        <li>Update the Decoder: Perform a manual update or rescan for channels through the decoder’s menu.</li>
                                        <li>Check Subscription Status: Verify that your subscription is active and has not expired.</li>
                                        <li>Inspect Dish and Connections: As with other issues, ensure that the dish and connections are in good condition.</li>
                                    </ul>

                                    <h4 className="text-xl font-semibold mb-4">4. Error Codes</h4>
                                    <p className="text-slate-400 mb-4">Error codes can provide clues about what might be wrong with your DStv service. Common error codes include:</p>
                                    <ul className="list-disc pl-5 mt-2">
                                        <li>Error Code E16: Indicates a signal issue. Check dish alignment and connections.</li>
                                        <li>Error Code E30: Refers to a problem with the LNB. Inspect the LNB and connections for faults.</li>
                                        <li>Error Code E48-32: Indicates a problem with your subscription. Contact DStv customer support for assistance.</li>
                                    </ul>

                                    <h4 className="text-xl font-semibold mb-4">5. Decoder Not Switching On</h4>
                                    <p className="text-slate-400 mb-4">If your decoder isn’t powering on, try these steps:</p>
                                    <ul className="list-disc pl-5 mt-2">
                                        <li>Check the Power Source: Ensure the decoder is properly plugged in and the power source is working.</li>
                                        <li>Inspect the Power Cable: Look for any visible damage to the power cable and replace if necessary.</li>
                                        <li>Try a Different Outlet: Plug the decoder into a different power outlet to rule out outlet issues.</li>
                                    </ul>

                                    <h4 className="text-xl font-semibold mb-4">When to Call a Professional</h4>
                                    <p className="text-slate-400 mb-4">If you’ve tried the above solutions and are still experiencing issues, it may be time to contact a professional. Complex problems such as hardware malfunctions or persistent signal issues often require expert intervention.</p>

                                    <p className="text-slate-400 italic border-x-4 border-red-500 rounded-ss-xl rounded-ee-xl mt-3 p-3">" For reliable DStv repair services, trust CT DStv & CCTV Experts. Our experienced team is here to diagnose and fix any issues with your DStv system swiftly and efficiently. "</p>
                                    <p className="text-slate-400 mt-3">Contact us today for professional assistance and ensure your DStv service runs smoothly.</p>
                                </div>
                            </div>
                        </div>

                        <BlogSidebar />
                    </div>
                </div>

                <Blogs />
            </section>
            <Footer />
            <Switcher />
        </>
    )
}
